const navToggle = document.querySelector("#navToggle"), navClosedIcon = document.querySelector("#navClosed"),
    navOpenIcon = document.querySelector("#navOpen"), nav = document.querySelector("nav");
navToggle.addEventListener("click", () => {
    nav.classList.toggle("open")
}), window.addEventListener("resize", () => {
    document.body.clientWidth > 720 && nav.classList.remove("open")
}, {passive: !1});

function toTop() {
    window.scrollTo({top: 0, behavior: "smooth"})
}
$('.clear').click(() => {
   window.location.reload()
    return false;
})
// $('.close').click(() => {
//     // $('#popup').addClass("d-none")
//     $(".popupBloc").addClass("d-none");
// })

